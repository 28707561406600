import axios from "@/config/httpConfig";

// 保存价格手册配置接口
export function savePriceBookSetUp(data) {
    return axios.post("/partnersort/savePriceBookSetUp", data);
}

// 查询价格手册配置接口
export function getPriceBookSetup(data) {
    return axios.post("/partnersort/getPriceBookSetup", data);
}

// 查询所有的的伙伴用户接口
export function getAllPartner(data) {
    return axios.post("/partnersort/getAllPartner", data);
}
// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}