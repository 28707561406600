<template>
  <!-- 开发人员：王雪茹 -->
  <div class="price_box" v-loading="detailLoading">
    <div class="home" ref="home">
      <!-- 基本信息 -->
      <div class="contentBox">
        <div class="box_top">
          <i class="iconfont iconjiantou-zuo left" @click="fanhui"></i>
          <div>
            <svg class="icon box_header" aria-hidden="true">
              <use href="#icon-hometab"></use>
            </svg>
            <div class="title">
              <span class="title_top">{{
                $t("lable.opppdt.selPbk.pricebook")
              }}</span>
              <br />
              <span class="title_main">{{ pricebookInfo.name }}</span>
            </div>
            <div class="header_right">
              <!-- 伙伴订货使用权限 -->
              <div
                class="partnerBtn"
                v-if="showItemTab"
                @click="partnerBtnMethod"
              >
                {{ $t("label.partnerCloud.partnerOrdering.permission") }}
              </div>
              <el-button-group>
                <el-button
                  class="button"
                  size="mini"
                  v-for="(item, index) in showButton"
                  :key="index"
                  @click="Urlbtn(item)"
                  >{{ item.label }}</el-button
                >
              </el-button-group>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="margin-top: 76px"></div> -->
      <!-- 详细信息-->
      <el-card class="box-main_tit" shadow="never">
        <div style="margin-bottom: 10px; border-bottom: 1px solid #dedc">
          <div class="info_s">{{ $t("label.more.info") }}</div>
        </div>
        <ul class="info_box">
          <li>
            <span>{{ $t("label.product.pricebooks.name") }}</span>
            <span>{{ pricebookInfo.name }}</span>
          </li>
          <li>
            <!-- 已启用 -->
            <span>{{ $t("label.inusing") }}</span>
            <el-checkbox
              :value="pricebookInfo.isenable === 'true' ? true : false"
              disabled
            ></el-checkbox>
          </li>
          <li>
            <!-- 描述 -->
            <span>{{ $t("label.file.des") }}</span>
            <span>{{ pricebookInfo.description }}</span>
          </li>
          <li>
            <!-- 是标准价格手册 -->
            <span>{{ $t("vue_label_commonobjects_detail_book") }}</span>
            <el-checkbox
              v-model="pricebookInfo.isStandardPriceBook"
              disabled
            ></el-checkbox>
          </li>
          <li>
            <!-- 创建人 -->
            <span>{{ $t("label.createby") }}</span>
            <span
              >{{ pricebookInfo.lastmodifybyid
              }}<b style="font-weight: normal"
                >，{{ pricebookInfo.lastmodifydate }}</b
              ></span
            >
          </li>
          <li>
            <!-- 上次修改人 -->
            <span>{{ $t("label.lasttimemodifier") }}</span>
            <span
              >{{ pricebookInfo.createbyid
              }}<b style="font-weight: normal"
                >，{{ pricebookInfo.createdate }}</b
              ></span
            >
          </li>
        </ul>
      </el-card>

      <!-- 价格手册条目 -->
      <el-card class="box-main_main" shadow="never">
        <div class="tit">
          <div class="tit_s">
            <svg class="icon box_header" aria-hidden="true">
              <use href="#icon-hometab"></use>
            </svg>
            <!-- 价格手册条目 -->
            <h5>{{ $t("label.product.entry") }}</h5>
          </div>
          <div style="position: relative">
            <el-button
              class="button"
              size="small"
              @click="rightBtn(1)"
              >{{ ButtonArr[0].label }}</el-button
            >
            <el-button
              class="button"
              size="small"
              @click="rightBtn(2)"
              v-if="pricebookInfo.isStandardPriceBook != true"
              >{{ ButtonArr[1].label }}</el-button
            >
          </div>
        </div>
        <div class="cont_table">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            class="price_table"
            :empty-text="$t('label.dashboard.noRecord')"
          >
            <!-- 产品名称 -->
            <el-table-column
              prop="productname"
              :label="$t('label.product.name')"
            >
              <template slot-scope="scope">
                <span
                  class="allow-click"
                  style="cursor: pointer; color: #006dcc"
                  @click="detail(scope.row)"
                  >{{ scope.row.productname }}</span
                >
              </template>
            </el-table-column>
            <!-- 产品代码 -->
            <el-table-column prop="" :label="$t('label.product.code')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.productcode }}</span
                >
              </template>
            </el-table-column>
            <!-- 产品系列 -->
            <el-table-column prop="" :label="$t('label.product.series')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.productfamily }}</span
                >
              </template>
            </el-table-column>
            <!-- 单位 -->
            <el-table-column prop="" :label="$t('label.custom.unit')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.unit }}</span
                >
              </template>
            </el-table-column>
            <!-- 产品类型 -->
            <el-table-column prop="" :label="$t('c1011')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.producttypeccname }}</span
                >
              </template>
            </el-table-column>
            <!-- 标准手册价格 -->
            <el-table-column prop="" :label="$t('c1012')" v-if="pricebookInfo.isStandardPriceBook != true">
              <template slot-scope="scope">
                <span v-show="scope.row.productstandardprice">{{ scope.row.currency }}&nbsp;</span>
                <span>{{ changeMicrometerLevel(scope.row.productstandardprice) }}</span>
              </template>
            </el-table-column>
            <!-- 价目表价格 -->
            <el-table-column prop="" :label="$t('lable.product.plist')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.currency }}&nbsp;{{ changeMicrometerLevel(scope.row.listPrice) }}</span
                >
              </template>
            </el-table-column>
            <!-- 是否启用 -->
            <el-table-column prop="isenable" :label="$t('label.isusing')">
              <template slot-scope="scope">
                <el-checkbox
                  :value="scope.row.isenable === 'true' ? true : false"
                  disabled
                ></el-checkbox>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column prop="address" label width="100">
              <template slot-scope="scope">
                <el-popover
                  placement="bottom-end"
                  width="10"
                  popper-class="pop_small"
                  :visible-arrow="false"
                  trigger="hover"
                >
                  <svg
                    class="icon little_hand"
                    aria-hidden="true"
                    slot="reference"
                  >
                    <use href="#icon-xiala"></use>
                  </svg>
                  <ul>
                    <li
                      @click="
                        handleTableAction(
                          scope,
                          $t('pagecreator_page_button_edit')
                        )
                      "
                    >
                      <!-- 编辑 -->
                      <span>{{ $t("pagecreator_page_button_edit") }}</span>
                    </li>
                    <li
                      @click="
                        handleTableAction(
                          scope,
                          $t('component_setup_tabs_label_delete')
                        )
                      "
                    >
                      <!-- 删除 -->
                      <span>{{ $t("component_setup_tabs_label_delete") }}</span>
                    </li>
                  </ul>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </el-card>
      <!-- 批量编辑 -->
      <el-dialog
        :title="$t('label.batcheditor')"
        :visible.sync="batchEditVisible"
        width="75%"
        class="batch_box"
      >
        <el-form
          :model="editForm"
          ref="editForm"
          :rules="editRules"
          class="demo-ruleForm mar-t-20"
          size="mini"
          label-position="left"
        >
          <el-table
            :data="editForm.tableData"
            :rules="editForm"
            stripe
            style="width: 100%"
            class="price_table"
            :empty-text="$t('label.dashboard.noRecord')"
            :header-cell-class-name="starAdd"
          >
            <!-- 产品名称 -->
            <el-table-column
              prop="productname"
              :label="$t('label.product.name')"
            >
              <template slot-scope="scope">
                <span class="allow-click">{{ scope.row.productname }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="pricebookname"
              :label="$t('lable.opppdt.selPbk.pricebook')"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.pricebookname }}</span>
              </template>
            </el-table-column>
            <!-- 币种 -->
            <el-table-column
              :label="$t('label.currency')"
              v-if="pricebookInfo.isStandardPriceBook == true"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.currency }}</span>
              </template>
            </el-table-column>
            <!-- 使用标准价格 -->
            <el-table-column
              prop="address"
              :label="$t('lable.product.pricebooks.ustand')"
              width="280"
              v-if="pricebookInfo.isStandardPriceBook != true"
            >
              <template slot-scope="scope">
                <span
                  >{{ scope.row.currency }}&nbsp;{{
                    scope.row.productstandardprice
                  }}&nbsp;</span
                >
                <el-checkbox v-model="scope.row.usestd" @change="useStandardprice($event, scope.row)"></el-checkbox>
              </template>
            </el-table-column>
            <!-- 价目表价格 -->
            <el-table-column :label="$t('lable.product.plist')" :width="pricebookInfo.isStandardPriceBook != true ? 280 : 350">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.standardprice'"
                  :rules="editRules.standardprice"
                >
                  <el-input
                    v-model="scope.row.standardprice"
                    autocomplete="off"
                    size="small"
                    :disabled="scope.row.usestd === true ? true : false"
                    style="width: 270px"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 已启用 -->
            <el-table-column
              prop="address"
              :label="$t('label.inusing')"
              :width="pricebookInfo.isStandardPriceBook != true ? 110 : 150"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isen"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="batchClose('editForm')" size="small">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <el-button type="primary" @click="batchSave('editForm')" size="small"
            ><!-- 确定 -->
            {{ $t("label.confirm") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- 添加产品 -->
      <el-dialog
        :title="$t('vue_label_opportunity_product_addproduct')"
        :visible.sync="addmanualVisible"
        width="50%"
        :before-close="closeAddmanualDialog"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
      >
        <!-- 当前价格手册： -->
        <div class="pop_tit">
          {{ $t("vue_label_pricebook_book") }}：{{ defaultManual }}
        </div>
        <div class="btn_box">
          <span @click="closeFilter">{{ filtername }}</span>
          <!-- 进行筛选 -->
          <el-button
            class="filter_btn"
            @click="filterBtn"
            :loading="filterLoading"
            >{{ $t("label.mobile.phone.screen") }}</el-button
          >
          <!-- 清除条件 -->
          <span @click="cleanFilter">{{ $t("label.delete.condition") }}</span>
        </div>
        <el-form
          :model="addmanualForm"
          class="demo-ruleForm mar-t-20"
          size="mini"
          label-position="left"
          v-show="filterFlag"
        >
          <!-- 筛选器 -->
          <div
            class="screen_s"
            v-for="(ev, index) in addmanualForm.list"
            :key="index"
          >
            <el-form-item>
              <span style="width: 20px">{{ index + 1 }}</span>
              <el-select
                v-model="ev.fieldname"
                :placeholder="$t('vue_label_commonobjects_detail_field')"
                class="select_one"
                filterable
                @change="selectChange($event, index)"
              >
                <el-option
                  v-for="item in optionst_one"
                  :key="item.apiname"
                  :label="item.labelName"
                  :value="item.apiname"
                ></el-option>
              </el-select>
              <!-- 运算符 -->
              <el-select
                v-model="ev.operator"
                :placeholder="$t('label.operator')"
                class="select_two"
                filterable
              >
                <el-option
                  v-for="item in ev.optionst_two"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <EditableCell
                ref="editableCell"
                :inputType="ev.inputType"
                :options="selecArr"
                :value="ev.condvalue"
                :max="1000000000000000000"
                @remoteSearch="remoteSearchs(ev, index)"
                style="width: 38%; position: relative"
              ></EditableCell>
              <div style="width: 100px">
                <span
                  class="filter_btn"
                  @click="dels(index)"
                  v-if="addmanualForm.list.length !== 1"
                  >-</span
                >
                <span
                  class="filter_btn"
                  @click="adds"
                  v-if="
                    addmanualForm.list.length - 1 === index &&
                    addmanualForm.list.length <= 4
                  "
                  >+</span
                >
              </div>
            </el-form-item>
          </div>
        </el-form>
        <el-table
          ref="addmanualData"
          :data="addmanualData"
          v-loadmore="loadMore"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 28px"
          :header-cell-style="{
            background: '#DDDBDA',
            color: '#333333',
            fontWeight: 400,
          }"
          class="add_table"
          height="300px"
          :empty-text="$t('label.dashboard.noRecord')"
          :row-key="getRowKeys"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          ></el-table-column>
          <!-- 产品名称 -->
          <el-table-column prop="name" :label="$t('label.product.name')">
            <template slot-scope="scope">
              <span style="color: #006dcc; cursor: pointer">{{
                scope.row.name
              }}</span>
            </template>
          </el-table-column>
          <!-- 价目表价格 -->
          <el-table-column :label="$t('lable.product.plist')" prop="date">
            <template slot-scope="scope">{{scope.row.currency}}&nbsp;{{ scope.row.listprice }}</template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addmanualClose" size="small"
            ><!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <el-button type="primary" @click="addmanualNext" size="small"
            ><!-- 下一步 -->
            {{ $t("UG_Forcast_09") }}</el-button
          >
        </span>
      </el-dialog>
      <!-- 详情页编辑 -->
      <el-dialog
        :title="$t('pagecreator_page_button_edit')"
        :visible.sync="dialogFormVisible"
        width="750px"
        class="edit_s"
      >
        <el-form
          :model="priceForm"
          :rules="rules"
          ref="priceForm"
          label-position="left"
        >
          <!-- 价格手册名 -->
          <el-form-item
            :label="$t('label.product.pricebooks.name')"
            :label-width="formLabelWidth"
            style="position: relative"
            prop="name"
          >
            <el-input
              v-model="priceForm.name"
              autocomplete="off"
              size="small"
              style="width: 270px"
            ></el-input>
            <div style="position: absolute; top: 0; right: 50px">
              <!-- 已启动 -->
              <span style="margin-right: 74px">{{ $t("label.inusing") }}</span>
              <el-checkbox v-model="isenable"></el-checkbox>
            </div>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item
            :label="$t('label.description')"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="priceForm.description"
              autocomplete="off"
              size="small"
              :disabled="priceForm.isenable === 'true' ? true : false"
              style="width: 270px"
            ></el-input>
            <div style="position: absolute; top: 0; right: 50px">
              <!-- 是标准价格手册 -->
              <span style="margin-right: 17px">{{
                $t("vue_label_commonobjects_detail_book")
              }}</span>
              <el-checkbox
                :value="priceForm.isStandardPriceBook === true ? true : false"
                disabled
              ></el-checkbox>
            </div>
          </el-form-item>
          <ul class="edit_ul">
            <li>
              <!-- 创建人 -->
              <span>{{ $t("label.createby") }}</span>
              <span
                >{{ pricebookInfo.lastmodifybyid }}，{{
                  pricebookInfo.lastmodifydate
                }}</span
              >
            </li>
            <li>
              <!-- 上次修改人 -->
              <span>{{ $t("label.lasttimemodifier") }}</span>
              <span
                >{{ pricebookInfo.createbyid }}，{{
                  pricebookInfo.createdate
                }}</span
              >
            </li>
          </ul>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm('priceForm')" size="small">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <el-button type="primary" @click="saveFun('priceForm')" size="small"
            ><!-- 保存 -->
            {{ $t("label.save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- 详情页产品编辑 -->
      <el-dialog
        :title="$t('pagecreator_page_button_edit')"
        :visible.sync="popVisible"
        width="600px"
      >
        <el-form
          :model="editFormData"
          :rules="rules"
          ref="editFormData"
          label-position="left"
        >
          <ul class="edit_ul">
            <li>
              <!-- 产品 -->
              <span>{{ $t("label.product") }}</span>
              <span>{{ editFormData.productname }}</span>
            </li>
            <li>
              <span>{{ $t("lable.opppdt.selPbk.pricebook") }}</span>
              <span>{{ editFormData.pricebookname }}</span>
            </li>
          </ul>
          <!-- 使用标准价格 -->
          <el-form-item
            :label="$t('lable.product.pricebooks.ustand')"
            :label-width="formLabelWidth"
            style="position: relative"
            v-if="pricebookInfo.isStandardPriceBook !== true"
          >
            {{ editFormData.currency }}&nbsp;{{ editFormData.productstandardprice }}
            <el-checkbox v-model="usestd" @change="changeUstd($event,editFormData)"></el-checkbox>
          </el-form-item>
          <!-- 价目表价格 -->
          <el-form-item
            :label="$t('lable.product.plist')"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="editFormData.standardprice"
              autocomplete="off"
              size="small"
              style="width: 270px"
              :disabled="this.usestd === true || pricebookInfo.isStandardPriceBook == true ? true : false"
            ></el-input>
          </el-form-item>
          <!-- 已启动 -->
          <el-form-item
            :label="$t('label.inusing')"
            :label-width="formLabelWidth"
            style="position: relative"
          >
            <el-checkbox v-model="isen"></el-checkbox>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="popVisible = false" size="small">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >

          <el-button type="primary" @click="savepopFun()" size="small">
            <!-- 保存 -->
            {{ $t("label.save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('pagecreator_page_button_edit')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span style="font-size: 14px; color: #080707">{{
          $t("label.weixin.confirm.delete")
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <!-- 删除 -->
          <el-button type="primary" @click="confirmDel" size="mini">{{
            $t("component_setup_tabs_label_delete")
          }}</el-button>
        </span>
      </el-dialog>
      <!-- 查找/查找多选 -->
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :modal-append-to-body="false"
        :before-close="beforeClose"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :relevantObjid="relevantObjId"
          :relevantPrefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </el-dialog>

      <!-- 伙伴订货使用权限 -->
      <partnerOrdering
        ref="getMethod"
        :partnerIsShow="partnerIsShow"
        @deletePartnerCancel="deletePartnerCancel"
        @deletePartnerSave="deletePartnerSave"
      ></partnerOrdering>
    </div>
  </div>
</template>
<script>
import partnerOrdering from "../commonObjects/components/partnerOrdering/partnerOrdering.vue";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import {
  DetailPriceBook,
  EditPriceBook,
  SavePriceBook,
  DeletePriceBook,
  BatchEditPage,
  SaveListPrice,
  AddProductToPricebook,
  EditPricebookItem,
  SaveProductPricebookItem,
  DeleteItem,
  SetListPriceToBook,
  GetViewGetSelectValue,
  GetCurrencyInfo,
  queryPartnerSetup,
} from "./api";
import operator from "@/config/enumCode/operator.js";
import filterValueInputType from "@/config/enumCode/inputType.js";
// import SearchTable from "@/components/Form/search-table.vue";
import * as CommonObjApi from "./api";

export default {
  name: "Activity",
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: { EditableCell, partnerOrdering },
  props: {},
  data() {
    return {
      partnerIsShow: false, //伙伴订货使用权限 显示隐藏
      isCustomer: false,
      height: "100%",
      dialogFormVisible: false,
      formLabelWidth: "140px",
      ButtonArr: [
        {
          id: 1,
          label: this.$i18n.t("label.batcheditor"), //批量编辑
        },
        {
          id: 2,
          label: this.$i18n.t("label.quote.choseproduct"), //添加产品
        },
      ],
      showButton: [
        {
          label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
        },
        {
          label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
        },
      ],
      tableData: [],
      editForm: {
        tableData: [],
      },
      editList: [],
      // tableHeight: "",
      batchEditVisible: false,
      //添加产品
      addmanualVisible: false,
      isAdd: false,
      addmanualData: [],
      addmanualForm: {
        list: [
          {
            fieldname: "",
            operator: "",
            condvalue: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
          },
        ],
      },
      initData: {
        fieldname: "",
        operator: "",
        condvalue: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
      },
      optionst_one: [],
      dataId: 1,
      filterFlag: true,
      pricebookInfo: {}, //详情信息
      priceForm: {},
      rules: {
        name: [{ required: true, trigger: "blur" }],
      },
      dialogVisible: false,
      isenable: false,
      usestdprice: false,
      popVisible: false,
      editFormData: {},
      usestd: false, //产品编辑
      isen: false, //产品编辑
      filtername: this.$i18n.t("vue_label_commonobjects_detail_filter"), //收起筛选器
      defaultManual: "",
      selecArr: [],
      condition: [], //筛选的数组
      SelectChangeArr: [],
      deleName: "",
      editRules: {
        standardprice: [
          {
            required: true,
            message: this.$i18n.t("label.price.list.price.cannot.be.empty"), //'价目表价格不可为空'
            trigger: "change",
          },
        ],
      },
      page: 1,
      pageSize: 20,
      noDataTip: false,
      manual: "",
      rowIndex: 0,
      showSearchTable: false,
      relevantObjId: "",
      relevantPrefix: "",
      row: {
        name: "",
      },
      showItemTab: false, //是否显示伙伴订货使用权限
      showItemTabData: "", //查询伙伴账户是否启用
      detailLoading: false,
      filterLoading: false,
      checkedArr: [],
    };
  },
  created() {},
  mounted() {
    this.getShowTab();
    this.detailPriceBook();
    this.changeSelect();
  },
  methods: {
    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 获取是否展示伙伴订货使用权限   勿删勿删勿删勿删勿删
    getShowTab() {
      let op = {};
      queryPartnerSetup(op).then((res) => {
        if (res.result) {
          // 判断伙伴账户是否启用
          this.showItemTabData = res.data.partnerworkspace;
          if (this.showItemTabData == "true") {
            // 判断是否有编辑价格手册权限
            CommonObjApi.getPermissionById({ id: this.$route.params.id }).then(
              (res) => {
                if (res.data.isEdit) {
                  this.showItemTab = true;
                }
              }
            );
          }
        }
      });
    },
    // 点击伙伴订货使用权限
    partnerBtnMethod() {
      this.partnerIsShow = true;
      this.$refs.getMethod.getPriceBookSetup();
    },
    // 取消"伙伴订货使用权限"弹框
    deletePartnerCancel() {
      this.partnerIsShow = false;
    },
    // 保存"伙伴订货使用权限"弹框
    deletePartnerSave() {
      this.partnerIsShow = false;
    },
    //跳转对应详情
    detail(row) {
      this.$router.push({
        path: `/commonObjects/detail/${row.productid}/DETAIL`,
        query: {
          viewId: "",
        },
      });
    },
    // 滚动事件
    loadMore() {
      let that = this;
      let dom = document.getElementsByClassName("add_table")[0].children[2];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 0) {
          //等于0证明已经到底，可以请求接口
          if (!that.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    //表格滚动懒加载
    append() {
      if (!this.noDataTip) {
        this.searchProducts();
      }
    },
    //获取详情信息
    detailPriceBook() {
      this.detailLoading = true;
      DetailPriceBook({ id: this.$route.params.id }).then((res) => {
        if (res.data) {
          this.pricebookInfo = res.data.pricebook;
          this.tableData = res.data.pricebookItems;
          if (!this.pricebookInfo.isCanDelete) {
            this.showButton = [
              {
                label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
              },
            ];
          }
          this.detailLoading = false;
        }
      });
    },
    //详情页右上角按钮
    Urlbtn(item) {
      // "编辑"
      if (item.label === this.$i18n.t("pagecreator_page_button_edit")) {
        if (this.pricebookInfo.isCanEdit) {
          CommonObjApi.getPermissionById({ id: this.$route.params.id }).then(
            (res) => {
              if (res.data.isEdit) {
                this.dialogFormVisible = true;
                EditPriceBook({ id: this.$route.params.id }).then((res) => {
                  this.priceForm = res.data;
                  if (this.priceForm.isenable === "true") {
                    this.isenable = true;
                  } else if (this.priceForm.isenable === "false") {
                    this.isenable = false;
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("report_label_norole"),
                  type: "warning",
                });
                //"抱歉，您无权编辑该条数据，请联系系统管理员。"
              }
            }
          );
        } else {
          this.$message.warning(this.$i18n.t("batch.edit.permission")); //"抱歉，您无权编辑该条数据，请联系系统管理员。"
        }
        //"删除"
      } else if (
        item.label === this.$i18n.t("component_setup_tabs_label_delete")
      ) {
        this.priceId = this.$route.params.id;
        if (
          this.$store.state.userInfoObj.profileId === "aaa000001" ||
          name == "Attachement"
        ) {
          this.dialogVisible = true;
        } else {
          CommonObjApi.getPermissionById({ id: this.priceId }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true;
            } else {
              this.$message(
                this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
              );
            }
          });
        }
      }
    },
    // 确认删除数据
    confirmDel() {
      if (this.deleName === "proct") {
        DeleteItem({ itemid: this.priceId }).then((res) => {
          if (res.result) {
            // "删除成功"
            this.$message.success(
              `${this.row.name}${this.$i18n.t("vue_label_pricebook_deleted")}`
            );
            this.dialogVisible = false;
            this.detailPriceBook();
          } else {
            this.$message.error(
              `${this.row.name}${this.$i18n.t("label.ems.delete.false")}`
            ); //删除失败
          }
        });
      } else {
        DeletePriceBook({
          id: this.priceId,
        }).then(() => {
          this.dialogVisible = false;
          this.$message.success(
            `${this.pricebookInfo.name}${this.$i18n.t(
              "vue_label_pricebook_deleted"
            )}`
          );
          this.$router.go(-1);
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //编辑保存
    saveFun(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;

          if (this.isenable) {
            this.priceForm.isenable = "true";
          } else {
            this.priceForm.isenable = "false";
          }
          SavePriceBook(this.priceForm).then((res) => {
            if (res.result) {
              // 保存成功
              this.$message.success(this.$i18n.t("label.search.saveok"));
              this.detailPriceBook();
            } else {
              this.$message.error(this.$i18n.t("savefail")); //"保存失败"
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      this.priceForm = {};
    },
    //批量编辑和添加产品
    rightBtn(n) {
      if (n === 1) {
        //"批量编辑"
        this.batchEditVisible = true;
        this.isAdd = false;
        BatchEditPage({ id: this.$route.params.id }).then((res) => {
          this.$set(res.data, "usestd", false);
          this.$set(res.data, "isen", false);
          res.data.pricebookItems.forEach((val) => {
            if (val.usestdprice === "true") {
              val.usestd = true;
              // 使用标准价格时，价目表价格赋值为标准价格
              val.standardprice = val.productstandardprice;
            } else if (val.usestdprice === "false") {
              val.usestd = false;
            }
            if (val.isenable === "true") {
              val.isen = true;
            } else if (val.isenable === "false") {
              val.isen = false;
            }
          });
          this.editForm.tableData = res.data.pricebookItems;
        });
      } else if (n === 2) {
        //"添加产品"
        this.page = 1;
        this.cleanFilter();
        this.addmanualVisible = true;
        this.isAdd = true;
        this.searchProducts();
      }
    },
    closeAddmanualDialog() {
      this.addmanualVisible = false;
    },
    starAdd(obj) {
      if (obj.columnIndex === 3) {
        return "star";
      }
    },
    //批量编辑取消
    batchClose(formName) {
      this.batchEditVisible = false;
      this.$refs[formName] && this.$refs[formName].resetFields();
    },
    //批量编辑保存
    batchSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.batchEditVisible = false;
          let copyList = JSON.parse(JSON.stringify(this.editForm.tableData));
          if (this.isAdd) {
            copyList.forEach((val) => {
              this.$set(val, "itemid", "");
            });
          }
          copyList.forEach((val, i) => {
            if (val.usestd === true) {
              val.useStdPrice = "true";
            } else if (val.usestd === false) {
              val.useStdPrice = "false";
            }
            if (val.isen === true) {
              val.isenable = "true";
            } else if (val.isen === false) {
              val.isenable = "false";
            }
            let productstandardprice = copyList[i].productstandardprice
            copyList[i].productId = copyList[i].productid;
            copyList[i].pricebookId = copyList[i].pricebookid;
            // 标准价格
            copyList[i].productstandardprice = productstandardprice;
            // 价目表价格
            copyList[i].listPrice = copyList[i].standardprice;
            delete copyList[i].usestd;
            delete copyList[i].isen;
            delete copyList[i].ownerid;
            delete copyList[i].pricebookname;
            delete copyList[i].productname;
            delete copyList[i].standardprice;
            delete copyList[i].usestdprice;
            delete copyList[i].pricebookid;
            delete copyList[i].productid;
          });
          SaveListPrice(copyList).then((res) => {
            if (res.result) {
              // 保存成功
              this.$message.success(this.$i18n.t("label.search.saveok"));
              this.detailPriceBook();
            } else {
              this.$message.error(this.$i18n.t("savefail")); //"保存失败"
            }
          });
        }
      });
    },
    //产品列表的操作按钮
    handleTableAction(scoped, n) {
      // "编辑"
      if (n === this.$i18n.t("pagecreator_page_button_edit")) {
        CommonObjApi.getPermissionById({ id: scoped.row.id }).then((res) => {
          if (res.data.isEdit) {
            this.popVisible = true;
            EditPricebookItem({ itemid: scoped.row.id }).then((res) => {
              if (res.data.itemInfo.isenable === "false") {
                this.isen = false;
              } else if (res.data.itemInfo.isenable === "true") {
                this.isen = true;
              } else {
                this.isen = false;
              }
              if (res.data.itemInfo.usestdprice === "false") {
                this.usestd = false;
              } else if (res.data.itemInfo.usestdprice === "true") {
                this.usestd = true;
              } else {
                this.usestd = false;
              }
              // 编辑数据新增--标准价格数据
              this.$set(res.data.itemInfo,'productstandardprice',scoped.row.productstandardprice)
              // 存价目表价格
              this.$set(res.data.itemInfo,'standardpriceOld',res.data.itemInfo.standardprice)
              this.editFormData = res.data.itemInfo;
            });
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("report_label_norole"),
              type: "warning",
            });
            //"抱歉，您无权编辑该条数据，请联系系统管理员。"
          }
        });

        // "删除"
      } else if (n === this.$i18n.t("component_setup_tabs_label_delete")) {
        this.deleName = "proct";
        this.row.name = scoped.row.productname;
        this.priceId = scoped.row.id;
        if (
          this.$store.state.userInfoObj.profileId === "aaa000001" ||
          name == "Attachement"
        ) {
          this.dialogVisible = true;
        } else {
          CommonObjApi.getPermissionById({ id: this.priceId }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true;
            } else {
              this.$message(
                this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
              );
            }
          });
        }
      }
    },
    // 价格手册单个产品编辑是否使用标准价格切换
    changeUstd(val, data) {
      if(val == true) {
        this.editFormData.standardprice = data.productstandardprice
      } else {
        this.editFormData.standardprice = data.standardpriceOld;
      }
    },
    //产品编辑保存
    savepopFun() {
      this.popVisible = false;
      let data = {
        itemid: this.editFormData.itemid,
        productId: this.editFormData.productid,
        pricebookId: this.editFormData.pricebookid,
        useStdPrice: this.usestd === true ? "true" : "false",
        listPrice: this.editFormData.standardprice,
        currency: this.editFormData.currency,
        isenable: this.isen === true ? "true" : "false",
        productstandardprice: this.editFormData.productstandardprice,
      };
      SaveProductPricebookItem(data).then((res) => {
        if (res.result) {
          // 保存成功
          this.$message.success(this.$i18n.t("label.search.saveok"));
          this.detailPriceBook();
        } else {
          this.$message.error(this.$i18n.t("savefail")); //"保存失败"
        }
      });
    },
    //返回上一页
    fanhui() {
      this.$router.go(-1);
    },
    //添加产品 新增
    adds() {
      this.dataId = 1;
      if (this.dataId < 5) {
        this.dataId += 1;
        this.addmanualForm.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //添加产品 删除
    dels(n) {
      this.addmanualForm.list.splice(n, 1);
      this.$refs.editableCell.splice(n, 1);
    },
    //添加产品 筛选下拉
    selectChange(val, index) {
      this.optionst_one.map((item) => {
        if (val === item.apiname) {
          this.addmanualForm.list[index].operator = "";
          this.addmanualForm.list[index].optionst_two =
            operator.TYPE[item.schemefieldType].getScreeningOpeTypes();

          this.addmanualForm.list[index].condvalue = {
            value: "",
          };
          this.addmanualForm.list[index].inputType =
            filterValueInputType[item.schemefieldType];
          this.selecArr = [];
          if (item.labelName === this.$i18n.t("label.currency")) {
            //币种
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else {
            GetViewGetSelectValue({
              fieldId: val,
            }).then((res) => {
              res.data.forEach((item) => {
                (item.label = item.codekey), (item.value = item.codevalue);
              });
              this.selecArr = res.data;
            });
          }
        }
      });
    },
    //添加产品 关闭筛选器
    closeFilter() {
      if (this.filterFlag) {
        this.filtername = this.$i18n.t(
          "vue_label_commonobjects_detail_openfilter"
        ); //展开筛选器;
      } else {
        this.filtername = this.$i18n.t("vue_label_commonobjects_detail_filter"); //收起筛选器
      }
      this.filterFlag = !this.filterFlag;
    },
    //筛选列表
    filterBtn() {
      let copyList = JSON.parse(JSON.stringify(this.addmanualForm.list));
      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].condvalue = item.editValue;
        });
      } else {
        copyList.forEach((item) => {
          item.condvalue = item.condvalue.value;
        });
      }
      this.condition = copyList;
      this.page = 1;
      this.filterLoading = true;
      this.searchProducts();
    },
    //添加产品 清除筛选器
    cleanFilter() {
      this.$nextTick(() => {
        if (this.$refs.addmanualData !== undefined) {
          this.$refs.addmanualData.clearSelection();
        }
      });
      this.addmanualForm.list = [
        {
          fieldname: "",
          operator: "",
          condvalue: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
      ];
      this.condition = [];
    },
    //获取添加产品数据
    searchProducts() {
      this.noDataTip = false;
      let data = {
        id: this.$route.params.id,
        condition: this.condition.length > 0 ? this.condition : [], //条件
        keywords: "", //关键字
        page: this.page,
        pageSize: this.pageSize,
      };
      AddProductToPricebook(data).then((res) => {
        this.optionst_one = res.data.fieldlist;
        this.defaultManual = res.data.pricebook.name;
        if (this.page === 1) {
          this.addmanualData = [];
        }
        if (res.data.pricelist.length === 0 && this.page === 1) {
          this.addmanualData = [];
        } else if (this.addmanualData.length === 0 || this.page === 1) {
          this.addmanualData = res.data.pricelist;
          this.page++;
        } else {
          this.addmanualData = [...this.addmanualData, ...res.data.pricelist];
          this.addmanualData.forEach((element) => {
            this.checkedArr.forEach((item) => {
              if (item.id === element.id) {
                element.checked = true;
              }
            });
          });
          this.page++;
        }
        //是否提示无更多数据
        if (res.data.pricelist.length === 0) {
          this.noDataTip = true;
        }
        this.filterLoading = false;
      });
    },
    //添加产品 表格多选
    handleSelectionChange(val) {
      this.checkedArr = val;
      this.SelectChangeArr = [];
      val.map((item) => {
        this.SelectChangeArr.push(item.id);
      });
    },
    getRowKeys(row) {
      return row.id;
    },
    //添加产品 关闭弹窗
    addmanualClose() {
      this.addmanualVisible = false;
    },
    //添加产品 下一步
    addmanualNext() {
      if (this.SelectChangeArr.length === 0) {
        //至少选择一个产品
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_opportunity_related_chooseproduct"),
          type: "warning",
        });
      } else {
        let data = {
          id: this.$route.params.id,
          productIds: this.SelectChangeArr.join(","),
        };

        SetListPriceToBook(data).then((res) => {
          res.data.priceList.forEach((val) => {
            this.$set(val, "isen", true);
          });
          this.editForm.tableData = res.data.priceList;
          this.batchEditVisible = true;
        });
        this.addmanualVisible = false;
      }
    },
    // 批量编辑使用标准价格
    useStandardprice(val, row) {
      if(val === true) {
        row.standardprice = row.productstandardprice;
      } else {
        row.standardprice = row.listPrice;
      }
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    remoteSearchs(item, num) {
      this.rowIndex = num;
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    //管理小组 用户信息
    changeSelect(n) {
      this.addmanualForm.list.forEach((item, index) => {
        if (this.rowIndex === index) {
          if (n && n.length !== 0) {
            let options = {
              label: n.data.id,
              value: n.data.name,
            };
            this.$set(
              this.addmanualForm.list[this.rowIndex],
              "condvalue",
              options
            );
          } else {
            this.$set(this.addmanualForm.list[this.rowIndex], "condvalue", n);
          }
        }
      });
      this.showSearchTable = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #005fb2;
  border-color: #005fb2;
}
.price_box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .home {
    height: 100%;
    overflow: auto;
    ::v-deep .add_table {
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background: #005fb2 !important;
        border-color: #005fb2 !important;
      }
      .el-table__body-wrapper {
        // overflow-y: auto;
        overflow-y: auto;
        max-height: 260px;
        // 提示语
        .noDataTip {
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          background: #fff;
        }
      }
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
    }
    ::v-deep .batch_box {
      th.star div::before {
        content: "*";
        color: red;
      }
      .el-form-item__error {
        position: static;
      }
      .el-table__body-wrapper {
        max-height: 400px !important;
        overflow-y: auto !important;
      }
    }
    .edit_s {
      ::v-deep .el-form-item__label:before {
        margin-left: -10px !important;
      }
    }
    .edit_ul {
      margin-bottom: 0;
      li {
        line-height: 40px;
        margin-bottom: 0;
        span:first-child {
          width: 140px;
          display: inline-block;
        }
        font-size: 14px;
      }
    }
    .contentBox {
      width: 100%;
      position: sticky;
      left: 0px;
      right: 0px;
      z-index: 999;
      top: 0px;
    }

    ::v-deep .el-card__body {
      padding: 0px;
    }
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #ccc;
      padding: 20px;
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #ccc;
      padding: 20px;
    }
    .price_table {
      ::v-deep .is-leaf {
        background: #fafafa;
        color: #333333;
      }
      border: 1px solid #ccc;
    }
    .pop_tit {
      margin-bottom: 20px;
      font-size: 12px;
    }
    .btn_box {
      span {
        color: #006dcc;
        font-size: 12px;
        margin-right: 20px;
        cursor: pointer;
      }
      .filter_btn {
        padding: 0;
        border: 0;
        color: #006dcc;
        font-size: 12px;
        margin-right: 10px;
        margin-left: 0;
      }
      .filter_btn:hover {
        background: none;
      }
    }
    .screen_s > div {
      margin-bottom: 0 !important;
    }

    .screen_s ::v-deep .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .el-form-item {
      margin-bottom: 0;
    }

    .select_one {
      width: 180px;
      margin: 6px;
    }

    .select_two {
      width: 140px;
      margin: 6px;
    }

    .select_three {
      width: 72px;
      margin: 6px;
    }
    .filter_btn {
      font-size: 24px;
      margin: 0 8px;
      cursor: pointer;
    }
    .box-main_tit {
      width: 100%;
      position: relative;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      margin-top: 10px;
      padding: 10px;
      box-sizing: border-box;
      .info_s {
        font-weight: 600;
        font-size: 16px;
        color: #080707;
        width: 84px;
        text-align: center;
        height: 36px;
        line-height: 32px;
        border-bottom: 2px solid #006dcc;
      }
      .info_box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          border-bottom: 1px solid #dedc;
          width: 49%;
          line-height: 35px;
          span:first-child {
            display: inline-block;
            width: 250px;
          }
        }
      }
    }
    .box-main_main {
      width: 100%;
      min-height: 70px;
      border-radius: 3px;
      border: 1px solid rgba(221, 219, 218, 1);
      margin-top: 10px;
      .cont_table {
        padding: 10px;
        box-sizing: border-box;
        .price_table {
          ::v-deep .is-leaf {
            background: #fafafa;
            color: #333333;
          }
          ::v-deep td,
          th {
            padding: 8px 0 !important;
          }
          border: 1px solid #ccc;
        }
      }

      .tit {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background: #f5f5f5;
        padding: 0 10px;
        box-sizing: border-box;
        height: 48px;
        align-items: center;
        .tit_s {
          svg {
            margin-right: 7px;
            float: left;
            width: 20px;
            height: 20px;
          }
          h5 {
            margin: 0;
            float: right;
            height: 14px;
            font-size: 14px;
            font-weight: 500;
            color: #080707;
            line-height: 21px;
          }
        }
        .xiala {
          width: 28px;
          height: 28px;
          float: right;
          text-align: center;
          position: relative;
          cursor: pointer;
          img {
            cursor: pointer;
          }
          li {
            cursor: pointer;
          }
        }
        .button {
          height: 30px;
          text-align: center;
          padding: 0 8px;
          border-radius: 3px;
          color: #006dcc;
          &:hover {
            background: #fff;
            border: 1px solid #dcdfe6;
          }
        }
      }
    }
  }
}

.box_top {
  background: #f3f2f2;
  border: 1px solid #dddbda;
  border-radius: 3px;
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  min-height: 69px;
  .left {
    width: 7px;
    height: 12px;
    margin-left: 11px;
    margin-top: 17px;
    margin-right: 12px;
    float: left;
    cursor: pointer;
  }

  .box_header {
    float: left;
    width: 36px;
    height: 36px;
    margin-top: 10px;
  }

  .title {
    height: 36px;
    float: left;
    margin-top: 8px;
    margin-left: 8px;

    .title_top {
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(62, 62, 60, 1);
      line-height: 18px;
    }

    .title_main {
      height: 15px;
      font-size: 16px;
      font-weight: 600;
      color: #080707;
      line-height: 15px;
    }
  }

  .header_right {
    float: right;
    padding-right: 10px;
    // 伙伴订货使用权限
    .partnerBtn {
      float: left;
      margin: 16px 15px 0 0;
      height: 30px;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      padding: 0 15px;
      color: #006dcc;
      background: #fff;
      cursor: pointer;
    }
    ::v-deep .el-button-group {
      margin-top: 16px;
      position: relative;
      .moreButton {
        margin-bottom: 0;
        li {
          height: 30px;
          line-height: 30px;

          &:hover {
            color: #006dcc;
          }
        }
      }
      .button {
        height: 30px;
        text-align: center;
        padding: 0 15px;
        color: #006dcc;
        &:hover {
          background: #fff;
          border: 1px solid #dcdfe6;
        }
      }
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
}

.pop_small li span {
  padding-left: 10px;
}

.pop_small li:hover {
  background: #f3f2f2;
  cursor: pointer;
  color: #006dcc;
}
</style>
