import axios from '@/config/httpConfig'

// 获取全部价格手册
export function GetAllPriceBooks(data) {
  return axios.post('/pricebook/getAllPriceBooks', data)
}

// 获取当前用户启用的价格手册
export function GetUserEnablePriceBooks(data) {
  return axios.post('/pricebook/getUserEnablePriceBooks', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}
// 查询伙伴云设置
export function queryPartnerSetup(data) {
  return axios.get('/partnerSetup/queryPartnerSetup', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}
// 删除价格手册
export function DeletePriceBook(data) {
  return axios.post('/pricebook/deletePriceBook', data)
}

// 价格手册编辑页
export function EditPriceBook(data) {
  return axios.post('/pricebook/editPriceBook', data)
}

// 价格手册详情页
export function DetailPriceBook(data) {
  return axios.post('/pricebook/detailPriceBook', data)
}

// 保存价格手册
export function SavePriceBook(data) {
  return axios.post('/pricebook/savePriceBook', data)
}

// 启用/禁用价格手册
export function SetEnable(data) {
  return axios.post('/pricebook/setEnable', data)
}

// 价格手册页批量编辑价格条目页
export function BatchEditPage(data) {
  return axios.post('/pricebook/batchEditPage', data)
}

// 批量保存价目表
export function SaveListPrice(data) {
  return axios.post('/pricebook/saveListPrice', data)
}

// 删除价目表记录
export function DeleteItem(data) {
  return axios.post('/pricebook/deleteItem', data)
}

// 价格手册中添加产品页
export function AddProductToPricebook(data) {
  return axios.post('/pricebook/addProductToPricebook', data)
}

// 产品详情页上价格手册条目查询
export function QueryProductPricebookItems(data) {
  return axios.post('/pricebook/queryProductPricebookItems', data)
}

// 产品详情页上添加标准价格手册
export function AddStdPricebookItems(data) {
  return axios.post('/pricebook/addStdPricebookItems', data)
}

// 产品详情页上添加价格手册
export function AddCustomPricebookItems(data) {
  return axios.post('/pricebook/addCustomPricebookItems', data)
}

// 产品详情页编辑价目表
export function EditPricebookItem(data) {
  return axios.post('/pricebook/editPricebookItem', data)
}

// 产品页保存价目表
export function SaveProductPricebookItem(data) {
  return axios.post('/pricebook/saveProductPricebookItem', data)
}

// 将产品中添加价格手册页面
export function SetListPriceToBook(data) {
  return axios.post('/pricebook/setListPriceToBook', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}
