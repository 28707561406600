<!--
  伙伴订货使用权限
-->
<template>
  <el-dialog
    :visible="partnerIsShow"
    :title="$t('label.partnerCloud.partnerOrdering.permission')"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="textDescribe">
        <!-- 该设置影响伙伴用户订货时，是否可以使用该价格手册。 -->
        <p>{{ $t("label.partnerCloud.priceOfManual.isUsed") }}</p>
        <!-- 建议为每个合作伙伴客户设置唯一的价格手册。若设置了多个价格手册，伙伴下单时需选择本单使用的价格手册。若未设置手册，会使用标准价格手册。 -->
        <p>
          {{ $t("label.partnerCloud.priceOfManual.theOnly") }}
        </p>
      </div>
      <div class="partnerBox">
        <div class="optionsTitle">
          <!-- 全部客户 -->
          <el-radio
            v-model="radio"
            label="allcustomers"
            @change="optionsCheckMethod"
            >{{ $t("label.partnerCloud.priceOfManual.allCustomer") }}</el-radio
          >
          <!-- <el-radio v-model="radio" label="b" @change="optionsCheckMethod">符合条件</el-radio> -->
          <!-- 选定客户 -->
          <el-radio
            v-model="radio"
            label="selectedcustomers"
            @change="optionsCheckMethod"
            >{{ $t("label.partnerCloud.priceOfManual.selected") }}</el-radio
          >
          <!-- 不启用 -->
          <el-radio
            v-model="radio"
            label="nouse"
            @change="optionsCheckMethod"
            >{{ $t("label.partnerCloud.priceOfManual.notEnabled") }}</el-radio
          >
        </div>
        <!-- 全部客户 和  不启用 -->
        <div class="optionsContent-tree" v-show="isShowOne"></div>
        <!-- 选定客户 展示内容为 搜索框   -->
        <div class="optionsContent-tree" v-show="!isShowOne">
          <selectUserSearch
            ref="www"
            @accountChecked="accountChecked"
            :checkedAccountId="checkedAccountId"
          ></selectUserSearch>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- 取消  保存 -->
      <el-button @click="deletePartnerCancel">
        {{ $t("label.emailsync.button.cancel") }}
      </el-button>
      <el-button type="primary" @click="deletePartnerSave">
        {{ $t("label.chatter.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import selectUserSearch from "./components/selectUserSearch.vue";
import * as partnerOrderingApi from "./api";
export default {
  components: {
    selectUserSearch,
  },
  props: {
    partnerIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radio: "nouse",
      isShowOne: true,
      priceId: this.$route.params.id, //价格手册id
      accountIdArray: [], //选中客户id
      checkedAccountId: [], //选中客户name
    };
  },
  watch: {
    radio(val) {
      if (val === "allcustomers" || val === "nouse") {
        this.isShowOne = true;
        this.accountIdArray = [];
      } else {
        this.isShowOne = false;
      }
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("deletePartnerCancel");
    },
    deletePartnerCancel() {
      this.$emit("deletePartnerCancel");
    },
    optionsCheckMethod(v) {
      if (v === "allcustomers" || v === "nouse") {
        this.isShowOne = true;
        this.accountIdArray = [];
      } else {
        this.isShowOne = false;
      }
    },
    // 获取选中的客户
    accountChecked(v) {
      this.accountIdArray = v;
    },
    // 保存  价格手册配置
    deletePartnerSave() {
      this.$emit("deletePartnerSave");
      let op = {
        pricebookid: this.priceId,
        permissiontype: this.radio,
        userid: this.accountIdArray,
      };
      partnerOrderingApi.savePriceBookSetUp(op).then(() => {
        this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
      });
    },

    // 查询价格手册配置
    getPriceBookSetup() {
      this.checkedAccountId = [];
      let op = {
        priceBookId: this.priceId,
      };
      partnerOrderingApi.getPriceBookSetup(op).then((res) => {
        this.radio = res.data.permissiontype;
        let radioData = res.data.userids;
        if (radioData === "") {
          this.checkedAccountId = [];
        } else {
          radioData.forEach((el) => {
            this.checkedAccountId.push(el.name);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 870px;
}
.dialog-body {
  width: 100%;
  // 文字描述
  .textDescribe {
    width: 100%;
    p {
      margin: 0;
    }
  }
  // 主题内容 三个单选项
  .partnerBox {
    width: 100%;
    padding: 15px 0 10px 0;
    .optionsTitle {
      width: 100%;
    }
    .optionsContent-two {
      width: 100%;
      // height: 200px;
      // border: solid 1px;
      margin: 20px 0 0 0;
    }
  }
}
</style>
