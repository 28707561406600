<template>
  <div class="selectUserBox">
    <el-select
      v-model="checkedUsers"
      filterable
      class="no-suffix"
      multiple
      remote
      :placeholder="$t('label.attendance.keywords')"
      style="width: 100%"
    >
      <p class="searchTipBox">
        <span
          @click="remoteSearch()"
          style="display: inline-block; width: 100%"
        >
          <i class="el-icon-search"></i>
          <span style="padding-left: 10px">
            <!-- 前往精准搜索 -->
            {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
          </span>
        </span>
      </p>
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
      <el-option v-show="false" key="new" :label="$t('label.new')" value="新建">
      </el-option>
    </el-select>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          :projectId="projectId"
          :milestone="milestone"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SearchTable from "@/components/Form/search-table.vue"; //成员搜索弹框
import Bus from "@/mixin/bus.js";
export default {
  components: { SearchTable },
  name: "selectUserSearch",
  props: {
    checkedAccountId: {
      type: Array,
    },
  },
  data() {
    return {
      options: [], //选中的查找对象   客户类型
      checkedUsers: [], //选中的查找对象的id
      showSearchTable: false,
      fieldId: "ffe100000001265aWyi8", //所有人
      checked: true,
      relevantObjId: "account", // 查找对象的id    客户类型
      relevantObjApi: "Account", // 查找对象的api   客户类型
      relevantPrefix: "001", // 查找对象的前缀     客户类型
      projectId: "", // 项目id
      milestone: "",
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
    };
  },
  mounted() {
    Bus.$on("windowResize", this.windowResizeFn);
    this.checkedUsers = this.checkedAccountId;
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.windowResizeFn);
  },
  methods: {
    windowResizeFn(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    // 远程搜索方法
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 点击前往精准搜索--选中弹出窗中的数据
    changeSelect(row) {
      this.checkedUsers = [];
      this.options = [];
      let opData = [];
      let opValue = [];
      // this.options = [];
      if (row.length === 0) {
        this.checkedUsers = [];
        this.options = [];
      } else {
        row.forEach((val) => {
          opData.push({
            id: val.id,
            name: val.name,
          });
          opValue.push(val.id);
        });
      }
      this.options = opData;
      this.checkedUsers = opValue;
      this.showSearchTable = false;
      // 
      this.$emit("accountChecked", this.checkedUsers);
    },
  },
};
</script>
<style lang="scss">
// .selectUserBox .el-select__tags-text {
//   display: inline-block;
//   max-width: 250px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }
// .selectUserBox .el-select .el-tag__close.el-icon-close {
//   top: -5px;
// }
</style>
<style lang="scss" scoped>
// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  right: 25px;
  .el-icon-arrow-up {
    display: none;
  }
}
.selectUserBox {
  width: 100%;
  margin: 20px 0 0 0;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  &:hover {
    background: #f5f7fa;
  }
}
</style>